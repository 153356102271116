import React, { Children, Component } from "react";
import logoImage from "../assets/img/logo-new.svg";
import HeadContact from "../assets/img/mobile_head.svg";
import HeadGetQuote from "../assets/img/get_quote_head.svg";
import HeadUser from "../assets/img/Head_User.svg";
import HeadSearch from "../assets/img/HeadSearch.svg";
import HeadMenu from "../assets/img/HeadMenu.svg";
import EnvelopIcon from "../assets/img/Envelop-icon.svg";
import BoxIcon from "../assets/img/Box-icon.svg";
import ArrorIcon from "../assets/img/arrow-icon.svg";
import FedexLogo from "../assets/img/fedex.svg";
import SFlSaver from "../assets/img/SFl-Saver.svg";
import DHL from "../assets/img/DHL.svg";
import Tooltip from "@material-ui/core/Tooltip";
import api from "../utils/apiClient";

const formatter = new Intl.NumberFormat('en-IN', {
  style: 'currency',
  currency: 'INR',
});


const rateurl = "https://rates.sflworldwide.com/";
const commonUrl = "https://rates.sflworldwide.com/";
const productiourl = "https://www.sflworldwide.com/";
const newRateUrl = "https://hubapi.sflworldwide.com/getQuote/getRates";
const bookurl = `https://hub.sflworldwide.com/auth/SalesLeadsRedirect-page/`;
// const bookurl = `http://localhost:3001/auth/SalesLeadsRedirect-page/`;

let getRateValue = JSON.parse(localStorage.getItem("gerRates"));
console.log("Test = ",getRateValue);
let Baseurl = "";
let salesLeadID = localStorage.getItem("salesLeadID");
let totalWeight = 0;
let BaseUrlData = localStorage.getItem("BaseUrlData");
let PhoneNumberTollFree = "1-800-691-2335"
let hrefPhone = "tel:18006912335"
let indiaornot = "No"

let dataindia = localStorage.getItem("forIndia")
if(dataindia == "Yes"){
  indiaornot = "Yes"
  PhoneNumberTollFree = "1-800-833-4849"
  hrefPhone = "tel:18008334849"
}

localStorage.removeItem("BaseUrlData")

let LeadDetails = [JSON.parse(localStorage.getItem("LeadDetails"))];
var packageType = "";
let setCurrencyIcon = ""
let setCurrencyIconLabel = ""
if (LeadDetails[0] != null) {
  
  if(LeadDetails[0].FromCountryValue.value == "89"){

    setCurrencyIcon = "₹ "
    setCurrencyIconLabel = "INR "
  }else if(LeadDetails[0].FromCountryValue.value == "37"){
    setCurrencyIcon = "$c "
    setCurrencyIconLabel = "CAD "
  }else{
    setCurrencyIcon = "$ "
    setCurrencyIconLabel = "USD "
  }
  packageType = LeadDetails.PackageTypeValue;
  for (let index = 0; index < LeadDetails[0].ChargableWeight.length; index++) {
    totalWeight = totalWeight + parseInt(LeadDetails[0].ChargableWeight[index]);
  }

}
// localStorage.removeItem("forIndia")

if (getRateValue != null) {

  console.log("LeadDetails[0].FromCountryValue.value = ",LeadDetails[0].FromCountryValue.value)

  if(LeadDetails[0].FromCountryValue.value == "89"){
    var rates = 0
    for (let index = 0; index < getRateValue.length; index++) {
      rates = ((getRateValue[index].Rates)/82)
      console.log("rates",rates)
      Baseurl = window.btoa(
        getRateValue[index].ServiceType +
          "/" +
          getRateValue[index].MainServiceName +
          "/" +
          getRateValue[index].ServiceDisplayName +
          "/" +
          Math.ceil(rates).toFixed(2) +
          ""
      );
  
      let bookNowURL =
        bookurl + Baseurl + `?saleid=` + encodeURIComponent(salesLeadID);
      getRateValue[index].bookNowURL = bookNowURL;
      console.log("Baseurl = ",Baseurl)
    }

  }else if(LeadDetails[0].FromCountryValue.value == "37"){
    var rates = 0
    for (let index = 0; index < getRateValue.length; index++) {
      rates = (getRateValue[index].Rates/1.25)
      Baseurl = window.btoa(
        getRateValue[index].ServiceType +
          "/" +
          getRateValue[index].MainServiceName +
          "/" +
          getRateValue[index].ServiceDisplayName +
          "/" +
          Math.ceil(rates).toFixed(2) +
          ""
      );
  
      let bookNowURL =
        bookurl + Baseurl + `?saleid=` + encodeURIComponent(salesLeadID);
      getRateValue[index].bookNowURL = bookNowURL;
    }

  }else{
    for (let index = 0; index < getRateValue.length; index++) {
      Baseurl = window.btoa(
        getRateValue[index].ServiceType +
          "/" +
          getRateValue[index].MainServiceName +
          "/" +
          getRateValue[index].ServiceDisplayName +
          "/" +
          getRateValue[index].Rates.toFixed(2) +
          ""
      );
  
      let bookNowURL =
        bookurl + Baseurl + `?saleid=` + encodeURIComponent(salesLeadID);
      getRateValue[index].bookNowURL = bookNowURL;
    }
    
  }
  
}

console.log("getRateValue = ",getRateValue)

if(salesLeadID !=null){
  let mailData = {
    SalesLeadId: salesLeadID,
    ProposalStatus:"Auto Quote",
    ShipmentNumber: "0"
  };
  api
    .post(
      "salesLead/changeStatusOfSalesLead",
      mailData
    )
    .then((mailres) => {
      if (mailres.success) {
        // this.CallGetRate();
      } else {
        // this.CallGetRate();
        // cogoToast.error("errorrrrrrr");
      }
    });

}


class GetRate extends Component {
  constructor(props) {
    super(props);
  }


  urlclick() {
    window.location = "https://www.sflworldwide.com/";
  }

  showHideMenu() {
    let stylediv = document.querySelector("#sidebarCheck").style.display;
    if (stylediv == "none") {
      document.getElementById("sidebarCheck").style.display = "block";
    } else {
      document.getElementById("sidebarCheck").style.display = "none";
    }
  }
  GetNewGetRate() {
    window.location = commonUrl;
  }
  render() {
    const { dataaa } = this.props;
    const { AllRatesDetails } = this.props;
    return (
      <div className="get-quote-wrap">
        <div className="quote-header">
          <div className="container">
            <div className="header-inner">
              <div className="logo">
                <a href={BaseUrlData}>
                  <img src={logoImage} alt="SFL Worldwide" />
                </a>
              </div>
              <div className="header-main-menu">
                <ul>
                  <li>
                    <a href={BaseUrlData+"track-shipment/"}>
                      Tracking
                    </a>
                  </li>
                  <li>
                    <a href="https://hub.sflworldwide.com/">Book Shipment</a>
                  </li>
                  <li>
                    <a href={BaseUrlData + "contact-us"}>
                      Contact Us
                    </a>
                  </li>
                  <li className="head-contact-no">
                    <a href={hrefPhone}>
                      <img src={HeadContact}></img>
                      <label>{PhoneNumberTollFree}</label>
                    </a>
                  </li>
                  <li className="head-get-quote">
                    <a href="https://rates.sflworldwide.com/">
                      <img src={HeadGetQuote}></img>
                      <label>Get Quote</label>
                    </a>
                  </li>
                </ul>
              </div>
              <div className="header-right">
                <ul>
                  <li>
                    <a
                      href="#
                    "
                    >
                      <img src={HeadUser}></img>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img src={HeadSearch}></img>
                    </a>
                  </li>
                  <li className="head-menu-outer">
                    <a onClick={() => this.showHideMenu()}>
                      <img src={HeadMenu}></img>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="wizard-outer get-rates-outer">
          <div className="container">
            <div className="wizard-top-section">
              {LeadDetails?.map((leadItem) => (
                <ul>
                  <li className="get-col-1">
                    {leadItem.PackageTypeValue === "Envelope" ? (
                      <div className="get-envelop">
                        <img src={EnvelopIcon}></img>
                        <label>Envelop</label>
                      </div>
                    ) : null}
                    {leadItem.PackageTypeValue === "Package" ? (
                      <div className="get-envelop">
                        <img src={BoxIcon}></img>
                        <label>Box</label>
                        <p>
                          Weight: {leadItem.Weight} {leadItem.WeightType}
                        </p>
                      </div>
                    ) : null}
                  </li>
                  <li className="get-col-2 location-outer">
                    <div className="get-location fst">
                      <Tooltip title={leadItem.FromCountryValue?.label}>
                        <label>{leadItem.FromCountryValue?.label}</label>
                      </Tooltip>
                      {leadItem.FromZipCode == "" ? (
                        <Tooltip title={leadItem.FromCity}>
                          <p>City: {leadItem.FromCity}</p>
                        </Tooltip>
                      ) : (
                        <Tooltip title={leadItem.FromZipCode}>
                          <p>Zip Code: {leadItem.FromZipCode}</p>
                        </Tooltip>
                      )}
                    </div>
                    <span className="arrow-icon">
                      <img src={ArrorIcon}></img>
                    </span>
                    <div className="get-location lst">
                      <Tooltip title={leadItem.ToCountryValue?.label}>
                        <label>{leadItem.ToCountryValue?.label}</label>
                      </Tooltip>
                      {leadItem.ToZipCode == "" ? (
                        <Tooltip title={leadItem.ToCity}>
                          <p>City: {leadItem.ToCity}</p>
                        </Tooltip>
                      ) : (
                        <Tooltip title={leadItem.ToZipCode}>
                          <p>Zip Code: {leadItem.ToZipCode}</p>
                        </Tooltip>
                      )}
                    </div>
                  </li>
                  <li className="get-col-3 contact-outer desktop">
                    <ul>
                      <li>
                        <span>Name</span>
                        <Tooltip title={leadItem.ContactName}>
                          <label>{leadItem.ContactName}</label>
                        </Tooltip>
                      </li>
                      <li>
                        <span>Email</span>
                        <Tooltip title={leadItem.Email}>
                          <label>{leadItem.Email}</label>
                        </Tooltip>
                      </li>
                      <li>
                        <span>Phone Number</span>
                        <label>{leadItem.PhoneNumber}</label>
                      </li>
                    </ul>
                  </li>
                  <li className="get-col-3 contact-outer mobile">
                    <ul>
                      <li>
                        <span>Name</span>
                        <Tooltip title={leadItem.ContactName}>
                          <label>{leadItem.ContactName}</label>
                        </Tooltip>
                      </li>
                      <li>
                        <span>Phone Number</span>
                        <label>{leadItem.PhoneNumber}</label>
                      </li>
                      <li>
                        <span>Email</span>
                        <Tooltip title={leadItem.Email}>
                          <label>{leadItem.Email}</label>
                        </Tooltip>
                      </li>
                    </ul>
                  </li>
                </ul>
              ))}
            </div>
            <div className="rates-listing-outer">
              {getRateValue?.map((ratesItem) => (
                <ul>
                  <li>
                    <ul>
                      <li className="img">
                        <img src={ratesItem.urlIMG}></img>
                      </li>
                      <li className="heading">
                        <h2> {ratesItem.ServiceDisplayName}</h2>
                      </li>
                      <li className="delivery-date">
                        <label>Estimated Delivery</label>
                        <span className="date-time">
                          {ratesItem.Delivery_Date}
                        </span>
                      </li>

                      {ratesItem.Discounts &&
                      ratesItem.BaseP > ratesItem.Rates ? (
                        <li className="price saver-price">
                          <ul>
                            <li className="retail-outer">
                              <span
                                className="retail-price"
                                style={{ textDecoration: "line-through" }}
                              >
                                Retail: {setCurrencyIconLabel} {new Intl.NumberFormat().format(Math.ceil(ratesItem.BaseP))}
                              </span>
                              <span className="save-price">
                                Save {ratesItem.Discounts}
                              </span>
                            </li>
                            <li className="ac-price">
                            <small className="Currency">{setCurrencyIconLabel}</small> {new Intl.NumberFormat().format(Math.ceil(ratesItem.Rates))}
                              {LeadDetails[0].Weight > 1?(
                                <span className="perKgPriceDis">
                                    {(new Intl.NumberFormat().format((Math.ceil(ratesItem.Rates/LeadDetails[0].Weight)))) + "/- " + LeadDetails[0].WeightType}
                                </span>

                              ): ""}
                              
                            </li>
                          </ul>
                        </li>
                      ) : (
                        <li className="price">
                          <label>Price</label>
                          <span className="date-time">
                          <small className="Currency">{setCurrencyIconLabel}</small>  {new Intl.NumberFormat().format(Math.ceil(ratesItem.Rates))}
                            {LeadDetails[0].Weight > 1?(
                                <span className="perKgPrice">
                                    {(new Intl.NumberFormat().format((Math.ceil(ratesItem.Rates/LeadDetails[0].Weight)))) + "/- " + LeadDetails[0].WeightType}
                                </span>

                              ): ""}
                          </span>
                        </li>
                      )}

                      <li className="booking-btn">
                        <a href={ratesItem.bookNowURL}>Book Now</a>
                      </li>
                    </ul>
                  </li>
                </ul>
              ))}
            </div>
          </div>
        </div>
        <div className="container">
          <div className="get-rates-btn-outer">
            <a href={productiourl}>Back to Home</a>
            <a className="get-new-quote" onClick={this.GetNewGetRate}>
              Get a New Quote
            </a>
          </div>
        </div>
        <div
          className="sidebar-outer"
          style={{ display: "none" }}
          id="sidebarCheck"
        >
          <div className="sidenav_wrap">
            <a
              href="javscript:;"
              onClick={() => this.showHideMenu()}
              className="side_nav_close"
              aria-label="Close Sidebar"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="black"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M1.04962 14.95C1.23715 15.1375 1.49146 15.2428 1.75662 15.2428C2.02179 15.2428 2.27609 15.1375 2.46362 14.95L7.99962 9.414L13.5356 14.95C13.7242 15.1322 13.9768 15.233 14.239 15.2307C14.5012 15.2284 14.752 15.1232 14.9374 14.9378C15.1228 14.7524 15.228 14.5016 15.2303 14.2394C15.2326 13.9772 15.1318 13.7246 14.9496 13.536L9.41362 8L14.9496 2.464C15.1318 2.2754 15.2326 2.02279 15.2303 1.7606C15.228 1.4984 15.1228 1.24759 14.9374 1.06218C14.752 0.876772 14.5012 0.771603 14.239 0.769325C13.9768 0.767046 13.7242 0.867841 13.5356 1.05L7.99962 6.586L2.46362 1.05C2.27502 0.867841 2.02242 0.767046 1.76022 0.769325C1.49802 0.771603 1.24721 0.876772 1.0618 1.06218C0.876396 1.24759 0.771227 1.4984 0.768949 1.7606C0.76667 2.02279 0.867464 2.2754 1.04962 2.464L6.58562 8L1.04962 13.536C0.862151 13.7235 0.756836 13.9778 0.756836 14.243C0.756836 14.5082 0.862151 14.7625 1.04962 14.95Z"></path>
              </svg>
            </a>
            <div className="sidenav_inner">
              {indiaornot == "Yes"?(

                    <ul id="menu-sidenav-menu-1" className="dropdown-menu click-menu">
                    <li className="menu-item">
                    <a href={BaseUrlData} className="menu-link">
                      <i
                        className="icon before line-icon icon-home"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Home</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href="https://rates.sflworldwide.com/"
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-cursor"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Get Quote</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href="https://hub.sflworldwide.com/#/Shipments/ScheduledShipment/Addschedulefirst"
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-tag"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Book Shipment</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "track-shipment"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-target"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Tracking</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a href="https://hub.sflworldwide.com/" className="menu-link">
                      <i
                        className="icon before line-icon icon-user"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">My Account</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "about-us"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-briefcase"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">About Us</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "services"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-list"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Services</span>
                    </a>
                    </li>
                    
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "worldwide-destinations"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-globe-alt"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Worldwide Destinations</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "resources"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-notebook"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Resources</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a href={BaseUrlData + "faq"} className="menu-link">
                      <i
                        className="icon before line-icon icon-question"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">FAQs</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "contact-us/locations"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-location-pin"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Locations</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a href={BaseUrlData + "blog"} className="menu-link">
                      <i
                        className="icon before line-icon icon-info"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Blog</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "contact-us"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-envelope"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Contact Us</span>
                    </a>
                    </li>
                    <li className="menu-item">
                    <a
                      href={BaseUrlData + "search"}
                      className="menu-link"
                    >
                      <i
                        className="icon before line-icon icon-magnifier"
                        aria-hidden="true"
                      ></i>
                      <span className="menu-text">Site Search</span>
                    </a>
                    </li>
                    </ul>

              ):
              <ul id="menu-sidenav-menu-1" className="dropdown-menu click-menu">
              <li className="menu-item">
              <a href={BaseUrlData} className="menu-link">
                <i
                  className="icon before line-icon icon-home"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Home</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href="https://rates.sflworldwide.com/"
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-cursor"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Get Quote</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href="https://hub.sflworldwide.com/#/Shipments/ScheduledShipment/Addschedulefirst"
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-tag"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Book Shipment</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "track-shipment"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-target"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Tracking</span>
              </a>
              </li>
              <li className="menu-item">
              <a href="https://hub.sflworldwide.com/" className="menu-link">
                <i
                  className="icon before line-icon icon-user"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">My Account</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "about-us"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-briefcase"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">About Us</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "services"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-list"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Services</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "shipping-for-small-business"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-organization"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Small Business Shipping</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "shop-us-stores-ship-worldwide"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-globe"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">
                  Shop US and Ship worldwide
                </span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "carriers"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-paper-plane"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Major Carriers</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "worldwide-destinations"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-globe-alt"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Worldwide Destinations</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "resources"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-notebook"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Resources</span>
              </a>
              </li>
              <li className="menu-item">
              <a href={BaseUrlData + "faq"} className="menu-link">
                <i
                  className="icon before line-icon icon-question"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">FAQs</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "contact-us/locations"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-location-pin"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Locations</span>
              </a>
              </li>
              <li className="menu-item">
              <a href={BaseUrlData + "blog"} className="menu-link">
                <i
                  className="icon before line-icon icon-info"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Blog</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "contact-us"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-envelope"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Contact Us</span>
              </a>
              </li>
              <li className="menu-item">
              <a
                href={BaseUrlData + "search"}
                className="menu-link"
              >
                <i
                  className="icon before line-icon icon-magnifier"
                  aria-hidden="true"
                ></i>
                <span className="menu-text">Site Search</span>
              </a>
              </li>
              </ul>
              }
              
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GetRate;
